<template>
  <div
    id="ImageLoader"
    ref="MainContent"
    class="loader fixed top-0 w-full left-full bottom-0 text-white z-[99] flex items-center justify-center"
  >
    <div data-dialog-box class="relative z-10">
      <h3 class="text-6xl font-bold">LOADING</h3>
      <p class="loader--text">0%</p>
    </div>

    <div
      data-dialog-bg
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black"
    ></div>
  </div>
</template>

<script>
import { dialog_animation } from '@/gsap/dialog';
export default {
  name: 'ImageLoader',
  data() {
    return {
      dialog_animation: null,
    };
  },
  watch: {
    page_image_loaded() {
      console.log(this.page_image_loaded);
      this.page_image_loaded
        ? this.dialog_animation.close()
        : this.dialog_animation.open();
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
    this.dialog_animation.open();
  },
};
</script>
