/* eslint-disable no-unused-vars */
import gsap from "gsap"

gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});

export default {
    methods: {
        SetMarqueeLeft() {
            let marquee = this.$refs.Marquee
            let word_width = marquee.querySelectorAll("p")[0].offsetWidth

            gsap.fromTo(marquee, {
                x: "0px"
            }, {
                x: (word_width * -3) + "px",
                duration: 40,
                ease: "linear",
                repeat: -1
            })
        },
        SetMarqueeRight() {
            let marquee = this.$refs.Marquee
            let word_width = marquee.querySelectorAll("p")[0].offsetWidth

            gsap.fromTo(marquee, {
                x: (word_width * (marquee.querySelectorAll("p").length - 2) * -1) + "px"
            }, {
                x: (word_width * -1) + "px",
                duration: 40,
                ease: "linear",
                repeat: -1
            })
        }
    }
}