// import base_meta from '@/assets/base_meta.json';
import Vue from 'vue';
import store from '@/store';
import router from '@/router';

Vue.prototype.$ImageUrl = (item) => {
  // return item;
  return item == '' ? '' : 'https://www.hscbrake.com/php/' + item;
};

Vue.prototype.$GetColumn = (key) => {
  if (store.state.common_column_data == null) {
    return '';
  }
  const column_data = store.state.common_column_data.filter(
    (column) => column.column_name == key
  );
  return column_data.length > 0 ? column_data[0] : '';
};

Vue.prototype.$MoneyFormat = (price) => {
  let val = (price / 1).toFixed(0).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

Vue.prototype.$PageReady = () => {
  // title
  window.prerenderReady = true;
  // window.dataLayer.push({
  //   event: 'page_view',
  //   page_title: title,
  // });
};

Vue.prototype.$RedirectError = () => {
  var meta = document.createElement('meta');
  meta.setAttribute('property', 'prerender-status-code');
  meta.setAttribute('name', 'prerender-status-code');
  meta.content = '404';
  document.getElementsByTagName('head')[0].appendChild(meta);
  router.push('/error_page');
};

Vue.prototype.$LoadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

Vue.prototype.$AddZeroPad = (val) => {
  return parseInt(val) < 10 ? '0' + val : val;
};

// Vue.prototype.$GetMetaData = (title, description, image) => {
//   let meta_data = Object.assign({}, base_meta);
//   if (title != '') {
//     meta_data.title = title + '｜' + meta_data.title;
//     meta_data.meta[1].content = title + '｜' + meta_data.meta[1].content;
//   }
//   if (description != '') {
//     meta_data.meta[2].content = description
//       .replace(/(<([^>]+)>)/gi, '')
//       .slice(0, 160);
//     meta_data.meta[3].content = description
//       .replace(/(<([^>]+)>)/gi, '')
//       .slice(0, 160);
//     meta_data.meta[4].content = description
//       .replace(/(<([^>]+)>)/gi, '')
//       .slice(0, 160);
//   }
//   if (image != '') {
//     meta_data.meta[5].content = process.env.VUE_APP_BASEURL + image;
//   }
//   meta_data.meta[0].content =
//     process.env.VUE_APP_BASEURL + router.history.current.fullPath;

//   return meta_data;
// };
