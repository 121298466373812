<template>
  <section class="w-full overflow-hidden">
    <div ref="Marquee" class="gsap_item line_box whitespace-nowrap">
      <p
        class="
          inline-block
          text-transparent text-8xl
          font-black
          transform
          -skew-x-[10deg]
          opacity-30
          mr-4
        "
        style="text-stroke: 1px white"
        v-for="item in 5"
        :key="item.id"
      >
        HSC PERFORMANCE BRAKE SYSTEM
      </p>
    </div>
  </section>
</template>

<script>
import GSAP from "@/gsap/home/marquee";
export default {
  name: "HomeMarquee",
  mixins: [GSAP],
};
</script>