<template>
  <div class="absolute top-0 left-0 right-0 bottom-0 bg-zinc-900">
    <div
      class="opacity-10 w-full h-full"
      style="background-image: url('/img/bg_el.jpeg'); background-size: 100px"
    ></div>
  </div>
</template>

<script>
export default {
  name: "MainBg",
};
</script>