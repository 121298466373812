<template>
  <div
    id="app"
    class="relative flex flex-col min-h-screen overflow-hidden bg-black"
  >
    <MainNav />

    <MainLoading />

    <SearchDialog />

    <ImageLoader />

    <ShopCartDialog />

    <MainDialog />

    <router-view
      class="relative z-10 flex-1 transition-all duration-200 router-view"
      @load-image="LoadImage"
    ></router-view>
    <MainFooter />

    <MouseArrow ref="MouseArrow" />
  </div>
</template>

<script>
import MainNav from "@/components/MainNav.vue";
import MainFooter from "./components/MainFooter.vue";
import SearchDialog from "@/components/OrderSearchDialog.vue";
import MainLoading from "./components/MainLoading.vue";
import ImageLoader from "./components/ImageLoader.vue";
import MouseArrow from "./components/MouseArrow.vue";
import ShopCartDialog from "./components/ShopCartDialog.vue";
import MainDialog from "./components/MainDialog.vue";
import { getLocalStorage, setLocalStorage } from "./common/cookie";
import { image_loaded } from "@/gsap/image_loaded";
import { mapState } from "vuex";
import { APIViewPage } from "@/common/pixel_methods";
export default {
  name: "App",
  components: {
    MainNav,
    MainFooter,
    SearchDialog,
    MainLoading,
    ImageLoader,
    MouseArrow,
    ShopCartDialog,
    MainDialog,
  },
  computed: {
    ...mapState([
      "carousel_data",
      "news_data",
      "common_column_data",
      "dealer_data",
      "video_data",
      "question_data",
      "product_data",
    ]),
  },
  data() {
    return {
      image_loader: null,
    };
  },
  methods: {
    CheckShopCart() {
      let shop_cart = getLocalStorage("shop_cart");
      if (shop_cart) {
        shop_cart = JSON.parse(getLocalStorage("shop_cart"));
        this.$store.commit("SetShopCart", shop_cart);
      } else {
        shop_cart = [];
        setLocalStorage("shop_cart", JSON.stringify(shop_cart));
        this.$store.commit("SetShopCart", shop_cart);
      }
    },
    LoadImage() {
      this.$nextTick(() => {
        this.image_loader == null
          ? (this.image_loader = new image_loaded())
          : "";
        this.image_loader.loadImage();
      });
    },
    CheckDataLoad() {
      if (this.carousel_data == null) {
        this.$store.dispatch("GetCarouselData");
      }
      if (this.news_data == null) {
        this.$store.dispatch("GetNewsData");
      }
      if (this.common_column_data == null) {
        this.$store.dispatch("GetCommonColumnData");
      }
      if (this.dealer_data == null) {
        this.$store.dispatch("GetDealerData");
      }
      if (this.video_data == null) {
        this.$store.dispatch("GetVideoData");
      }
      if (this.question_data == null) {
        this.$store.dispatch("GetQuestionData");
      }
      if (this.product_data == null) {
        this.$store.dispatch("GetProductData");
      }
    },
  },
  watch: {
    $route(new_val, old_val) {
      if (new_val.name != old_val.name) {
        APIViewPage();
        this.$store.commit("SetImageLoaded", false);
      }
      this.CheckShopCart();
    },
  },
  created() {
    this.CheckDataLoad();
    this.CheckShopCart();
  },
  mounted() {
    APIViewPage();
    this.$refs.MouseArrow.InitCursor();
  },
};
</script>

<style src="@/assets/css/output.css"></style>
