import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/video/list',
    name: 'video_gallery',
    component: () => import('../views/VideoGalleryView.vue')
  },
  {
    path: '/video/page/:id',
    name: 'video_page',
    component: () => import('../views/VideoPageView.vue')
  },
  {
    path: '/news/list',
    name: 'news_list',
    component: () => import('../views/NewsListView.vue')
  },
  {
    path: '/news/page/:id',
    name: 'news_page',
    component: () => import('../views/NewsPageView.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/SupportView.vue')
  },
  {
    path: '/dealers',
    name: 'dealers',
    component: () => import('../views/DealersView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/ProductListView.vue')
  },
  {
    path: '/products/page/:id',
    name: 'product_page',
    component: () => import('../views/ProductPageView.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/ShopCartView.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/CheckOutView.vue')
  },
  {
    path: '/order_finish/:type',
    name: 'order_finish',
    component: () => import('../views/OrderFinishView.vue')
  },
  {
    path: '/order_search',
    name: 'order_search',
    component: () => import('../views/OrderResultView.vue')
  },
  {
    path: '/return_policy',
    name: 'return_policy',
    component: () => import('../views/ReturnPolicyView.vue')
  },
  {
    path: '/error_page',
    name: 'error_page',
    // component: () => import('../views/ErrorPageView.vue')
    component: () => import('../views/404Page.vue')
  },
  {
    path: "*",
    redirect: '/error_page',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router