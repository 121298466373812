<template>
  <section id="HomeSocial" class="mt-20">
    <div class="w-full max-w-screen-xl mx-auto">
      <div class="flex flex-wrap items-stretch">
        <a
          v-for="(item, item_index) in social_data"
          :class="item_index % 2 == 0 ? 'md:pr-2' : 'md:pl-2'"
          class="relative z-10 block w-full mb-4 overflow-hidden cursor-pointer md:w-1/2 md:mb-0"
          :key="item.id"
          :href="item.link"
          @mouseenter="hover_index = item_index"
          @mouseleave="hover_index = -1"
        >
          <div
            class="absolute z-[12] top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center transform transition-all duraction-300"
            :class="hover_index == item_index ? 'scale-125' : ''"
          >
            <h3 class="text-4xl font-black text-white">{{ item.title }}</h3>
            <p class="text-sm font-medium text-yellow-400">
              {{ item.sub_title }}
            </p>
          </div>
          <div class="relative w-full overflow-hidden aspect-h-9 aspect-w-16">
            <div
              class="absolute top-0 left-0 right-0 bottom-0 z-[11] bg-black transition-all duration-500"
              :class="
                hover_index == item_index ? 'bg-opacity-90' : 'bg-opacity-70'
              "
              style="background-image: url('/img/dot_cover.png')"
            ></div>
            <img
              :src="item.image"
              class="object-cover w-full h-full grayscale contrast-125"
            />
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeSocial',
  data() {
    return {
      hover_index: -1,
    };
  },
  computed: {
    social_data() {
      if (this.$store.state.common_column_data == null) {
        return [];
      }
      return [
        this.$store.getters.get_common_column('home_social_media_1'),
        this.$store.getters.get_common_column('home_social_media_2'),
      ];
    },
  },
};
</script>
