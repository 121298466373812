var default_meta = {
    title: "官方網站",
    content: "注重於制動系統開發測試，有八年的賽道經驗，秉持高品質、高安全性、高性能為目標給予客戶最安全的使用品質，另有多年台灣速克達動力系統開發研究。",
    image: "/img/share/index.jpg"
}

export function GetMetaData(title, content, image) {
    title = title == '' ? default_meta.title : title
    return {
        title: title + " | H.S.C | 鈊澳創企業社",
        meta: [{
                property: "og:description",
                content: content == '' ? default_meta.content : content,
                vmid: "og:description",
            },
            {
                property: "twitter:description",
                content: content == '' ? default_meta.content : content,
                vmid: "twitter:description",
            },
            {
                property: "description",
                content: content == '' ? default_meta.content : content,
                vmid: "description",
            },
            {
                property: "og:image",
                content: image == '' ? default_meta.image : image,
                vmid: "og:image",
            },
            {
                property: "apple-mobile-web-app-title",
                content: title + " | H.S.C | 鈊澳創企業社",
                vmid: "apple-mobile-web-app-title",
            },
            {
                property: "application-name",
                content: title + " | H.S.C | 鈊澳創企業社",
                vmid: "application-name",
            },
            {
                property: "og:site_name",
                content: "官方網站 | H.S.C | 鈊澳創企業社",
                vmid: "og:site_name",
            },
            {
                property: "og:type",
                content: "website",
                vmid: "og:type",
            },
            {
                property: "og:locale",
                content: "zh_tw",
                vmid: "og:locale",
            },
        ],
    }
}