/* eslint-disable no-unused-vars */
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});

export class main_product_02 {
  constructor(el) {
    this.el = el;
    this.bg_el = el.querySelectorAll('.bg_box div div');
    this.image = el.querySelector('img');
    this.title_cover = el.querySelector('.title_box span');
    this.title = el.querySelector('.title_box h3');
    this.sub_title = el.querySelector(' .sub_title');
    this.content = el.querySelector(' .content');
    this.more_btn = el.querySelector(' .more_btn');

    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline.fromTo(
      this.bg_el[0],
      {
        x: '-105%',
      },
      {
        x: '0%',
        ease: 'easeInOut',
        duration: 0.4,
      },
      0
    );
    this.timeline.fromTo(
      this.bg_el[1],
      {
        x: '-105%',
      },
      {
        x: '0%',
        ease: 'easeInOut',
        delay: 0.4,
        duration: 0.3,
      },
      0
    );
    this.timeline.fromTo(
      this.image,
      {
        webkitFilter: 'blur(50px)',
        transformOrigin: 'center',
        opacity: 0,
      },
      {
        webkitFilter: 'blur(0px)',
        transformOrigin: 'center',
        opacity: 1,
        delay: 0.4,
        duration: 0.5,
      },
      0
    );

    this.timeline.fromTo(
      this.sub_title,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.3,
        duration: 0.3,
      },
      0
    );
    this.timeline.fromTo(
      this.title_cover,
      {
        x: '-105%',
      },
      {
        x: '-0%',
        delay: 0.4,
        duration: 0.3,
      },
      0
    );
    this.timeline.set(
      this.title,
      {
        opacity: 1,
        delay: 0.7,
      },
      0
    );
    this.timeline.to(
      this.title_cover,
      {
        x: '110%',
        duration: 0.2,
      },
      1
    );
    this.timeline.fromTo(
      this.content,
      {
        y: '30px',
        opacity: 0,
      },
      {
        y: '0px',
        opacity: 1,
        duration: 0.6,
      },
      1
    );
    this.timeline.fromTo(
      this.more_btn,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.6,
        delay: 0.4,
      },
      1
    );
  }
}

// export default {

//     methods: {
//         SetGsap() {
//             let home_about = document.querySelector("#HomeMainProduct.main_product_02")
//             let bg_el = home_about.querySelectorAll(".bg_box div div")
//             let image = home_about.querySelector("img")
//             let title_cover = home_about.querySelector(".title_box span")
//             let title = home_about.querySelector(".title_box h3")
//             let sub_title = home_about.querySelector(" .sub_title")
//             let content = home_about.querySelector(" .content")
//             let more_btn = home_about.querySelector(" .more_btn")

//             let gsap_timeline = gsap.timeline({
//                 scrollTrigger: {
//                     trigger: home_about,
//                     start: "top 70%",
//                     toggleActions: "restart play play reverse"
//                 }
//             })
//             gsap_timeline.fromTo(bg_el[0], {
//                 x: "-105%"
//             }, {
//                 x: "0%",
//                 ease: "easeInOut",
//                 duration: .4
//             }, 0)
//             gsap_timeline.fromTo(bg_el[1], {
//                 x: "-105%"
//             }, {
//                 x: "0%",
//                 ease: "easeInOut",
//                 delay: .4,
//                 duration: .3
//             }, 0)
//             gsap_timeline.fromTo(image, {
//                 webkitFilter: "blur(50px)",
//                 transformOrigin: "center",
//                 opacity: 0
//             }, {
//                 webkitFilter: "blur(0px)",
//                 transformOrigin: "center",
//                 opacity: 1,
//                 delay: .4,
//                 duration: .5
//             }, 0)

//             gsap_timeline.fromTo(sub_title, {
//                 opacity: 0
//             }, {
//                 opacity: 1,
//                 delay: .3,
//                 duration: .3
//             }, 0)
//             gsap_timeline.fromTo(title_cover, {
//                 x: "-105%",
//             }, {
//                 x: "-0%",
//                 delay: .4,
//                 duration: .3
//             }, 0)
//             gsap_timeline.set(title, {
//                 opacity: 1,
//                 delay: .7
//             }, 0)
//             gsap_timeline.to(title_cover, {
//                 x: "110%",
//                 duration: .2
//             }, 1)
//             gsap_timeline.fromTo(content, {
//                 y: "30px",
//                 opacity: 0,
//             }, {
//                 y: "0px",
//                 opacity: 1,
//                 duration: .6
//             }, 1)
//             gsap_timeline.fromTo(more_btn, {
//                 opacity: 0,
//             }, {
//                 opacity: 1,
//                 duration: .6,
//                 delay: .4
//             }, 1)

//         }
//     },
//     computed: {
//         page_image_loaded() {
//             return this.$store.state.page_image_loaded
//         }
//     },
//     watch: {
//         page_image_loaded() {
//             if (this.page_image_loaded == true && this.main_products.length > 0) {
//                 this.$nextTick(() => {
//                     this.SetGsap()
//                 })
//             }
//         },
//         main_products() {
//             if (this.page_image_loaded == true && this.main_products.length > 0) {
//                 this.$nextTick(() => {
//                     this.SetGsap()
//                 })
//             }
//         }
//     }
// }
