<template>
  <div
    v-if="main_loading > 0"
    id="MainLoading"
    class="
      fixed
      top-0
      left-0
      right-0
      bottom-0
      z-[51]
      bg-black bg-opacity-80
      flex
      items-center
      justify-center
    "
  >
    <div>
      <svg
        id="圖層_2"
        data-name="圖層 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 463.27 420.95"
        class="w-44"
      >
        <path
          class="animate-[loading_10s_infinite]"
          stroke="yellow"
          fill="transparent"
          style="stroke-dasharray: 3000"
          d="M639.3,393.94h36L510.39,558.85l-4.48,10.77V669.68l26,10.77L661.35,551h36.06l-79.69,79.7a24.47,24.47,0,0,0,0,34.6l37,37a17.1,17.1,0,0,0,1.8,1.58,25.24,25.24,0,0,0,20,9.8H845a24.43,24.43,0,0,0,7.55-1.14c.4,0,.78,0,1.17-.09a17.83,17.83,0,0,0,3.21-.76,24.24,24.24,0,0,0,10.17-6.11l88.42-88.42L960,606.41V506.35l-26-10.77-115,115.06h-38L950.89,440.71l4.46-10.77V330.15l-23.68-12.67a25.93,25.93,0,0,0-3.9,3.23L798.68,449.8H762.6l78.34-78.31a24.52,24.52,0,0,0,0-34.61l-37.05-37a24.12,24.12,0,0,0-17.16-7.15H786a19.63,19.63,0,0,0-2.16.28,20.76,20.76,0,0,0-3-.32H612.41c-.83,0-1.61.18-2.42.24s-1.4-.22-2.12-.24h-.3a21,21,0,0,0-5,.57,24.7,24.7,0,0,0-3.8,1.16,23.88,23.88,0,0,0-8.58,5.46l-89,89.07-4.48,10.76V499.82l26,10.75Z"
          transform="translate(-496.69 -292.73)"
        />
      </svg>
      <p class="text-white text-4xl font-bold text-center mt-5">LOADING</p>
    </div>
  </div>
</template>
    

    <script>
export default {
  name: "MainLoading",
  computed: {
    main_loading() {
      return this.$store.state.main_loading;
    },
  },
};
</script>