<template>
  <section id="HomeProductCategory" class="mt-20">
    <div class="relative z-10 w-full max-w-screen-xl mx-auto">
      <div class="">
        <p class="text-lg font-bold text-yellow-400">PRODUCT CATGEGORY</p>
        <h3 class="my-4 text-6xl font-bold text-white">商品類型</h3>
        <!-- <p class="text-sm text-zinc-400">
          注重於制動系統開發測試，有八年的賽道經驗，秉持高品質、高安全性、高性能為目標<br />給予客戶最安全的使用品質，另有多年台灣速克達動力系統開發研究。
        </p> -->
      </div>
      <div class="flex flex-wrap w-full mt-10">
        <router-link
          v-for="(item, item_index) in product_category"
          class="relative block w-full md:w-1/2"
          :key="item.id"
          :to="item.link"
          @mouseover.native="hover_item = item_index"
          @mouseleave.native="hover_item = -1"
        >
          <div
            class="absolute z-[11] top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 flex items-center justify-center"
          >
            <div
              class="relative z-[11] text-center transition-all duration-300 transform"
              :class="hover_item == item_index ? 'scale-110' : ''"
            >
              <h3 class="text-4xl font-bold text-white">{{ item.title }}</h3>
              <p class="text-yellow-400">{{ item.sub_title }}</p>
            </div>
          </div>
          <div class="w-full overflow-hidden aspect-h-9 aspect-w-16">
            <img
              :src="item.image"
              :alt="item.title"
              :class="hover_item == item_index ? 'blur-sm ' : ''"
              class="object-cover object-center w-full h-full transition-all duration-500"
            />
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HomeProductCategory',

  data() {
    return {
      hover_item: -1,
    };
  },
  computed: {
    ...mapGetters(['get_common_column']),
    product_category() {
      const tmp_data = this.$store.state.common_column_data;
      if (tmp_data == null) {
        return [];
      } else {
        return [
          this.get_common_column('home_product_category_1'),
          this.get_common_column('home_product_category_2'),
          this.get_common_column('home_product_category_3'),
          this.get_common_column('home_product_category_4'),
        ];
      }
    },
  },
};
</script>
