<template>
  <section ref="MainContent" id="HomeNews" class="mt-20">
    <div class="relative z-10 w-full max-w-screen-xl mx-auto">
      <div class="">
        <p class="text-lg font-bold text-yellow-400">NEWS</p>
        <h3 class="my-4 text-6xl font-bold text-white">最新消息</h3>
        <!-- <p class="text-sm text-zinc-400">
          注重於制動系統開發測試，有八年的賽道經驗，秉持高品質、高安全性、高性能為目標<br />給予客戶最安全的使用品質，另有多年台灣速克達動力系統開發研究。
        </p> -->
      </div>
    </div>
    <div class="w-full mt-10">
      <ol class="w-full whitespace-nowrap gsap_item">
        <li
          v-for="item in news_data"
          :key="`news_list_1_${item.news_id}`"
          class="relative inline-block w-11/12 px-4 md:w-4/5 md:px-14 gsap_item"
        >
          <router-link
            to="/"
            class="relative block w-full overflow-hidden image_box aspect-h-9 aspect-w-16"
          >
            <img
              :src="item.cover_image"
              :alt="item.title"
              class="block object-cover w-full h-full"
            />
            <span
              class="time_line absolute top-auto bottom-0 left-0 w-full h-1 bg-yellow-400 z-10 transform -translate-x-[105%]"
            ></span>
          </router-link>
          <div
            class="flex flex-wrap items-center justify-between mt-2 md:flex-nowrap md:mt-6"
          >
            <div
              class="w-full mb-2 font-bold tracking-widest text-center text-gray-500 transform date_box md:text-lg md:absolute md:-left-4 md:top-14 md:rotate-90 md:w-auto md:text-left md:mb-0"
            >
              {{ item.created_at.slice(0, 10) }}
            </div>
            <div class="w-full text-center md:w-auto md:text-left">
              <h3 class="text-xl font-bold text-white">{{ item.title }}</h3>
              <p class="text-sm font-bold text-yellow-400">
                {{ item.category }}
              </p>
            </div>
            <router-link
              class="px-6 py-1 mx-auto mt-2 text-sm text-yellow-400 transition-colors duration-500 border border-yellow-400 md:py-2 md:mx-0 md:mt-0 hover:bg-yellow-400 hover:text-black"
              :to="`/news/page/${item.news_id}`"
              >READ MORE</router-link
            >
          </div>
        </li>
        <li
          v-for="item in news_data"
          :key="`news_list_${item.news_id}`"
          class="relative inline-block w-11/12 px-4 md:w-4/5 md:px-14 gsap_item"
        >
          <a
            :href="`news/page/${item.news_id}`"
            class="relative block w-full overflow-hidden image_box aspect-h-9 aspect-w-16"
          >
            <img
              :src="item.cover_image"
              :alt="item.title"
              class="block object-cover w-full h-full"
            />
            <span
              class="time_line absolute top-auto bottom-0 left-0 w-full h-1 bg-yellow-400 z-10 transform -translate-x-[105%]"
            ></span>
          </a>
          <div
            class="flex flex-wrap items-center justify-between mt-2 md:flex-nowrap md:mt-6"
          >
            <div
              class="w-full mb-2 font-bold tracking-widest text-center text-gray-500 transform date_box md:text-lg md:absolute md:-left-4 md:top-14 md:rotate-90 md:w-auto md:text-left md:mb-0"
            >
              {{ item.created_at.slice(0, 10) }}
            </div>
            <div class="w-full text-center md:w-auto md:text-left">
              <h3 class="text-xl font-bold text-white">{{ item.title }}</h3>
              <p class="text-sm font-bold text-yellow-400">
                {{ item.category }}
              </p>
            </div>
            <a
              class="px-6 py-1 mx-auto mt-2 text-sm text-yellow-400 transition-colors duration-500 border border-yellow-400 md:py-2 md:mx-0 md:mt-0 hover:bg-yellow-400 hover:text-black"
              :href="`/news/page/${item.news_id}`"
              >READ MORE</a
            >
          </div>
        </li>
      </ol>
      <div class="flex justify-center w-full mt-5">
        <button
          @click="home_news_animation.PrevList()"
          class="flex items-center mr-6 text-gray-400 transition-colors duration-300 hover:text-yellow-400"
        >
          <span
            class="text-6xl transform scale-y-50 material-icons-outlined -scale-x-150"
          >
            trending_flat
          </span>
        </button>
        <button
          @click="home_news_animation.NextList()"
          class="flex items-center ml-6 text-gray-400 transition-colors duration-300 hover:text-yellow-400"
        >
          <span
            class="text-6xl transform scale-x-150 scale-y-50 material-icons-outlined"
          >
            trending_flat
          </span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { home_news } from '@/gsap/home/news';
export default {
  name: 'HomeNews',
  data() {
    return {
      home_news_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.home_news_animation = new home_news(this.$refs.MainContent);
    },
  },
  computed: {
    news_data() {
      return this.$store.state.news_data;
    },
  },
  beforeDestroy() {
    this.home_news_animation.destroy();
  },
};
</script>
