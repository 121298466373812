<template>
  <section
    ref="MainContent"
    id="HomeMainProduct"
    class="w-full mt-0 overflow-hidden main_product_02"
  >
    <div
      v-if="main_products != null"
      class="relative flex flex-col-reverse items-center justify-center w-full max-w-screen-xl py-4 mx-auto md:flex-row"
    >
      <div
        class="relative z-10 w-full px-4 pt-10 text-right text-white md:w-1/2 sm:px-10 md:bg-transparent md:pt-0"
      >
        <p class="text-xs font-bold text-yellow-400 opacity-0 sub_title">
          {{ main_products.sub_title }}
        </p>
        <div class="relative inline-block my-2 overflow-hidden title_box">
          <span
            class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full bg-yellow-300"
          ></span>
          <h3 class="text-5xl font-bold opacity-0">
            {{ main_products.title }}
          </h3>
        </div>
        <p
          class="mb-5 text-xs font-medium leading-loose tracking-wider opacity-0 content text-zinc-400 xl:text-sm"
          v-html="main_products.content"
        ></p>
        <a
          :href="main_products.link"
          class="px-4 py-2 text-xs font-bold text-yellow-400 transition-colors duration-500 border border-yellow-400 opacity-0 more_btn hover:bg-yellow-400 hover:text-black"
          >READ MORE</a
        >
      </div>
      <div class="relative z-10 w-full px-4 mb-10 md:w-1/2 lg:mb-0 sm:px-10">
        <div class="md:w-full w-[85%] mx-auto overflow-hidden">
          <img
            :src="main_products.image"
            :alt="main_products.title"
            class="object-cover w-full h-full opacity-0 gsap_item"
          />
        </div>
      </div>
      <div
        class="bg_box absolute md:top-1/2 top-0 right-0 md:opacity-100 opacity-50 flex justify-end transform md:-translate-y-1/2 translate-x-[8%] md:h-2/3 h-2/3"
      >
        <div
          class="flex-shrink-0 lg:w-[350px] md:w-[100px] w-[350px] h-full transform -skew-x-[20deg] overflow-hidden relative lg:mr-10 mr-10"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full bg-gray-400 bg-opacity-20 gsap_item"
          ></div>
        </div>
        <div
          class="flex-shrink-0 lg:w-44 md:w-[300px] w-44 h-full transform -skew-x-[20deg] overflow-hidden relative"
        >
          <div
            class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-full bg-yellow-400 bg-opacity-50 gsap_item"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { main_product_02 } from '@/gsap/home/main_product_02';
export default {
  name: 'HomeAbout',
  data() {
    return {
      main_product_02: null,
    };
  },
  methods: {
    SetGsap() {
      this.main_product_02 = new main_product_02(this.$refs.MainContent);
    },
  },
  computed: {
    main_products() {
      return this.$store.getters.get_common_column('home_main_product_2');
    },
  },
};
</script>
