import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMeta from 'vue-meta';
import '@/common/global_methods';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});

//plugin
// import imagesLoaded from '@/gsap/image_loaded';
import VueGtm from '@gtm-support/vue2-gtm';
import prerender_event from '@/common/prerender_event';
Vue.mixin(prerender_event);
// Vue.mixin(imagesLoaded);
Vue.use(VueGtm, {
  id: 'GTM-N436TNN',
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
