/* eslint-disable no-unused-vars */
import gsap from 'gsap';

gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});

export class home_carousel {
  constructor(el) {
    this.el = el;
    this.carousel_box = el.querySelector('.home_carousel .carousel_box');
    this.carousel_item = el.querySelectorAll('.home_carousel .carousel_item');
    this.timeline = el.querySelector('.home_carousel .time_line');

    this.active_carousel = 0;
    this.carousel_timer = null;
    this.carousel_timeline = null;
    this.text_timeline = null;
    this.progress_timeline = null;
    this.setup();
  }

  setup() {
    this.carousel_timer != null ? clearTimeout(this.carousel_timer) : '';
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';

    this.SetTimeline();

    let text_box = this.carousel_item[0].querySelector('.text_box');
    let dark_cover = this.carousel_item[0].querySelector('.dark_cover');
    let text_line = this.carousel_item[0].querySelector('.text_line');

    this.carousel_timeline = gsap.timeline();

    this.carousel_timeline.fromTo(
      dark_cover,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        delay: 0.5,
      }
    );
    this.carousel_timeline.fromTo(
      text_line,
      {
        scaleX: 0,
        transformOrigin: 'left',
      },
      {
        scaleX: 1,
        transformOrigin: 'left',
        onComplete: () => {
          this.SetTextBox(text_box);
        },
      }
    );
    this.carousel_timer = setTimeout(() => {
      this.UpdateCarouselGsap(this.carousel_item, 1);
    }, 5000);
  }

  UpdateCarouselGsap(items, next_index) {
    gsap.to(this.carousel_box, {
      y: `-${next_index * 100}%`,
      duration: 0.5,
      onComplete: () => {
        let text_box = items[this.active_carousel].querySelector('.text_box');
        this.ResetTextBox(text_box);
        this.ResetCarousel(items[this.active_carousel]);
        this.SetCarousel(items[next_index], next_index);
      },
    });
  }

  ResetCarousel(carousel) {
    let dark_cover = carousel.querySelector('.dark_cover');
    gsap.set(dark_cover, {
      opacity: 0,
    });
  }

  SetCarousel(carousel, next_index) {
    this.carousel_timer != null ? clearTimeout(this.carousel_timer) : '';
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';

    this.active_carousel = next_index;
    this.SetTimeline();

    this.carousel_timer = setTimeout(() => {
      let next_active_index =
        next_index == this.carousel_item.length - 1 ? 0 : next_index + 1;
      this.UpdateCarouselGsap(this.carousel_item, next_active_index);
    }, 5000);

    let text_box = carousel.querySelector('.text_box');
    let dark_cover = carousel.querySelector('.dark_cover');
    let text_line = carousel.querySelector('.text_line');

    this.carousel_timeline = gsap.timeline();
    this.carousel_timeline.fromTo(
      dark_cover,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        delay: 0.5,
      }
    );
    this.carousel_timeline.fromTo(
      text_line,
      {
        scaleX: 0,
        transformOrigin: 'left',
      },
      {
        scaleX: 1,
        transformOrigin: 'left',
        onComplete: () => {
          this.SetTextBox(text_box);
        },
      }
    );
  }

  SetTextBox(text_box) {
    this.text_timeline != null ? this.text_timeline.kill() : '';
    let title = text_box.querySelector('.title');
    let title_cover = text_box.querySelector('.title_box span');
    let sub_title = text_box.querySelector('.sub_title');
    let button = text_box.querySelector('a');

    this.text_timeline = gsap.timeline();
    this.text_timeline.fromTo(
      sub_title,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.3,
      }
    );
    this.text_timeline.fromTo(
      title_cover,
      {
        x: '-105%',
      },
      {
        x: '-0%',
        duration: 0.3,
      }
    );
    this.text_timeline.set(title, {
      opacity: 1,
    });
    this.text_timeline.to(title_cover, {
      x: '105%',
      duration: 0.3,
    });
    this.text_timeline.fromTo(
      button,
      {
        y: '-10%',
        opacity: 0,
      },
      {
        y: '0%',
        opacity: 1,
        duration: 0.4,
        delay: 0.3,
      }
    );
  }

  ResetTextBox(text_box) {
    let title = text_box.querySelector('.title');
    let title_cover = text_box.querySelector('.title_box span');
    let sub_title = text_box.querySelector('.sub_title');
    let button = text_box.querySelector('a');
    gsap.set(sub_title, {
      opacity: 0,
    });
    gsap.set(title_cover, {
      x: '-100%',
    });
    gsap.set(title, {
      opacity: 0,
    });
    gsap.set(button, {
      y: '-10%',
      opacity: 0,
    });
  }

  SetTimeline() {
    this.progress_timeline != null ? this.progress_timeline.kill() : '';
    this.progress_timeline = gsap.timeline();

    this.progress_timeline.fromTo(
      this.timeline,
      {
        scaleY: 0,
        transformOrigin: 'top',
      },
      {
        scaleY: 1,
        transformOrigin: 'top',
        duration: 5,
      }
    );
  }

  destroy() {
    this.active_carousel = 0;
    this.carousel_timer != null ? clearTimeout(this.carousel_timer) : '';
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.text_timeline != null ? this.text_timeline.kill() : '';
    this.progress_timeline != null ? this.progress_timeline.kill() : '';
  }
}

// export default {
//     data() {
//         return {
//             active_carousel: 0,
//             carousel_timer: null
//         }
//     },
//     methods: {

//     },
//     beforeDestory() {
//         clearTimeout(this.carousel_timer)
//         this.active_carousel = 0
//     }
// }
