<template>
  <header class="fixed top-0 left-0 w-full z-30">
    <div
      :class="menu_bg ? 'md:bg-black md:bg-opacity-50' : ''"
      class="w-full max-w-full xl:px-8 md:px-6 px-4 mx-auto flex items-center justify-between transition-all duration-200"
    >
      <div
        :class="menu_bg ? 'lg:py-6 py-4 ' : 'lg:py-10 md:py-6 py-4'"
        class="header relative z-[41] lg:w-auto w-full flex items-center justify-between transition-all duration-200"
      >
        <router-link to="/"
          ><img
            :class="menu_bg ? 'xl:w-32 w-32' : 'xl:w-44 w-32'"
            class=""
            src="/img/logo.png"
        /></router-link>

        <div class="flex">
          <router-link
            to="/cart"
            class="block lg:hidden mr-3 relative overflow-hidden transition-all transform hover:-rotate-45"
          >
            <div
              class="w-10 h-10 bg-yellow-400 flex items-center justify-center rounded-full duration-300"
            >
              <span class="material-icons-two-tone text-lg">
                shopping_cart
              </span>
            </div>
          </router-link>
          <button
            class="block lg:hidden relative overflow-hidden transition-all transform hover:rotate-90"
            @click="SetMenuActive(!menu_active)"
          >
            <div
              class="absolute w-10 h-10 bg-white flex items-center justify-center rounded-full transform transition-opacity duration-300"
              :class="
                menu_active
                  ? 'opactiy-100 translate-x-0'
                  : 'opactiy-0 translate-x-full'
              "
            >
              <span class="material-icons-round text-gray-800"> close </span>
            </div>

            <div
              class="w-10 h-10 bg-gray-900 flex items-center justify-center rounded-full"
            >
              <span class="material-icons-round text-white"> menu </span>
            </div>
          </button>
        </div>
      </div>
      <div
        class="lg:relative fixed lg:w-auto w-full top-0 left-0 lg:bottom-auto bottom-0 lg:z-30 z-40 lg:bg-transparent bg-gray-900 bg-opacity-90 flex items-center transform transition-opacity duration-300"
        :class="
          menu_active
            ? 'opacity-100 translate-x-0'
            : 'lg:opacity-100 opacity-0 lg:translate-x-0 -translate-x-full'
        "
      >
        <ol
          class="flex items-center lg:flex-row flex-col lg:w-auto w-4/5 mx-auto"
        >
          <li v-for="item in menu_list" class="lg:w-auto w-full" :key="item.id">
            <router-link
              class="flex lg:flex-col-reverse flex-col px-6 lg:py-1 py-4 lg:tracking-widest lg:font-normal lg:border-b-0 text-white hover:text-yellow-400 lg:text-sm text-2xl lg:h-auto lg:w-auto w-full h-24 relative text-center overflow-hidden font-bold border-b border-gray-800 transition-color duration-300"
              @click.native="SetMenuActive(false)"
              :to="item.url"
            >
              {{ item.title }}
              <span class="block lg:text-lg text-base lg:font-bold">{{
                item.sub_title
              }}</span>
            </router-link>
          </li>
          <li class="lg:inline-block hidden pl-2 border-l border-yellow-400">
            <router-link
              to="/cart"
              class="px-4 py-2 relative flex items-center"
            >
              <!-- <p class="text-black font-bold text-xs">購物車</p> -->
              <span class="material-icons-outlined text-yellow-400">
                shopping_cart
              </span>
              <span
                class="transform flex justify-center items-center rounded-full text-yellow-400 ml-2 font-bold text-base"
                >{{ shop_cart }}</span
              >
            </router-link>
          </li>
        </ol>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainNav',
  data() {
    return {
      menu_bg: false,
      menu_list: [
        {
          title: '關於我們',
          sub_title: 'ABOUT',
          url: '/about',
        },
        {
          title: '商品列表',
          sub_title: 'PRODUCTS',
          url: '/products',
        },
        {
          title: '影音相簿',
          sub_title: 'VIDEOS',
          url: '/video/list',
        },
        {
          title: '最新消息',
          sub_title: 'NEWS',
          url: '/news/list',
        },
        {
          title: '客服中心',
          sub_title: 'SUPPORT',
          url: '/support',
        },
        {
          title: '經銷據點',
          sub_title: 'DEALERS',
          url: '/dealers',
        },
      ],
    };
  },
  computed: {
    menu_active() {
      return this.$store.state.main_menu;
    },
    shop_cart() {
      let amount = 0;
      this.$store.state.shop_cart.forEach((item) => {
        amount += parseInt(item.amount);
      });
      return amount;
    },
  },
  methods: {
    SetMenuActive(action) {
      this.$store.commit('SetMainMenu', action);
    },
    CheckScroll() {
      window.scrollY >= 200 || this.$route.name == 'dealers'
        ? (this.menu_bg = true)
        : (this.menu_bg = false);
    },
  },
  watch: {
    $route() {
      this.CheckScroll();
    },
  },
  mounted() {
    this.CheckScroll();
    window.addEventListener('scroll', () => {
      this.CheckScroll();
    });
  },
};
</script>
