/* eslint-disable no-unused-vars */
import gsap from "gsap";
import store from "@/store/index";
import imagesloaded from "imagesloaded";

export class image_loaded {
  constructor() {
    this.images = [];
    this.loader = document.querySelector(".loader--text");
  }
  loadImage() {
    console.log("set image loader");
    //鎖住body
    document.querySelector("#mouse_arrow").style.opacity = "0";
    document.body.style.overflow = "hidden";

    //初始化
    this.images = [];
    store.commit("SetImageLoaded", false);
    this.images = gsap.utils.toArray("img");

    imagesloaded(this.images)
      .on("progress", (instance) => {
        this.updateProgress(instance);
      })
      .on("always", () => {
        this.loadFinish();
      });
  }

  //更新進度條
  updateProgress(instance) {
    this.loader.innerHTML = `${Math.round(
      (instance.progressedCount * 100) / this.images.length
    )}%`;
  }

  //完成事件
  loadFinish() {
    //復原body
    document.body.style.overflow = "auto";
    window.scrollTo(0, 0);

    //設定讀取完成
    store.commit("SetImageLoaded", true);
    console.log("all images have been loaded!");

    //關閉讀取畫面
    // let image_loader = document.querySelector('#ImageLoader');
    // let time_line = gsap.timeline();
    // time_line.fromTo(
    //   image_loader,
    //   {
    //     opacity: 1,
    //   },
    //   {
    //     opacity: 0,
    //     direction: 0.3,
    //   }
    // );
    // time_line.set(image_loader, {
    //   display: 'none',
    // });
  }
}

// export default {
//   methods: {
//     LoadImage() {
//       //鎖住body
//       document.querySelector('#mouse_arrow').style.opacity = '0';
//       document.body.style.overflow = 'hidden';

//       //初始化
//       this.$store.commit('SetImageLoaded', false);
//       const images = gsap.utils.toArray('img');
//       const loader = document.querySelector('.loader--text');

//       //進度條
//       const updateProgress = (instance) => {
//         loader.innerHTML = `${Math.round(
//           (instance.progressedCount * 100) / images.length
//         )}%`;
//       };

//       //完成事件
//       const showDemo = () => {
//         //復原body
//         document.body.style.overflow = 'auto';
//         window.scrollTo(0, 0);

//         //設定讀取完成
//         this.$store.commit('SetImageLoaded', true);
//         console.log('all images have been loaded!');

//         //關閉讀取畫面
//         let image_loader = document.querySelector('#ImageLoader');
//         let time_line = gsap.timeline();
//         time_line.fromTo(
//           image_loader,
//           {
//             opacity: 1,
//           },
//           {
//             opacity: 0,
//             direction: 0.3,
//           }
//         );
//         time_line.set(image_loader, {
//           display: 'none',
//         });
//       };

//       window
//         .imagesLoaded(images)
//         .on('progress', updateProgress)
//         .on('always', showDemo);
//     },
//     ResetLoadImage() {
//       let image_loader = document.querySelector('#ImageLoader');
//       let time_line = gsap.timeline();
//       time_line.set(image_loader, {
//         display: 'flex',
//       });
//       time_line.fromTo(
//         image_loader,
//         {
//           opacity: 0,
//         },
//         {
//           opacity: 1,
//           duration: 0,
//         }
//       );
//     },
//   },
// };
