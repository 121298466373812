/* eslint-disable no-unused-vars */
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});

export default {
  methods: {
    SetGsap() {
      let home_about = document.querySelector('#HomeAbout');
      let bg_el = home_about.querySelectorAll('.bg_box div div');
      let image = home_about.querySelector('img');
      let title_cover = home_about.querySelector('.title_box span');
      let title = home_about.querySelector('.title_box h3');
      let sub_title = home_about.querySelector(' .sub_title');
      let content = home_about.querySelector(' .content');
      let more_btn = home_about.querySelector(' .more_btn');

      let gsap_timeline = gsap.timeline({
        scrollTrigger: {
          trigger: home_about,
          start: 'top 70%',
          toggleActions: 'restart play play reverse',
        },
      });
      gsap_timeline.fromTo(
        bg_el[1],
        {
          x: '105%',
        },
        {
          x: '0%',
          ease: 'easeInOut',
          duration: 0.4,
        },
        0
      );
      gsap_timeline.fromTo(
        bg_el[0],
        {
          x: '105%',
        },
        {
          x: '0%',
          ease: 'easeInOut',
          delay: 0.4,
          duration: 0.3,
        },
        0
      );
      gsap_timeline.fromTo(
        image,
        {
          // webkitFilter: "blur(500px)",
          y: '100px',
          opacity: 0,
        },
        {
          y: '0px',
          opacity: 1,
          delay: 0.4,
          duration: 0.5,
        },
        0
      );

      gsap_timeline.fromTo(
        sub_title,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.3,
          duration: 0.3,
        },
        0
      );
      gsap_timeline.fromTo(
        title_cover,
        {
          x: '-105%',
        },
        {
          x: '-0%',
          delay: 0.4,
          duration: 0.3,
        },
        0
      );
      gsap_timeline.set(
        title,
        {
          opacity: 1,
          delay: 0.7,
        },
        0
      );
      gsap_timeline.to(
        title_cover,
        {
          x: '110%',
          duration: 0.2,
        },
        1
      );
      gsap_timeline.fromTo(
        content,
        {
          y: '30px',
          opacity: 0,
        },
        {
          y: '0px',
          opacity: 1,
          duration: 0.6,
        },
        1
      );
      gsap_timeline.fromTo(
        more_btn,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          delay: 0.4,
        },
        1
      );
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded == true && this.about_data != null) {
        this.$nextTick(() => {
          this.SetGsap();
        });
      }
    },
    about_data() {
      if (this.page_image_loaded == true && this.about_data != null) {
        this.$nextTick(() => {
          this.SetGsap();
        });
      }
    },
  },
};
