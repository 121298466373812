/* eslint-disable no-unused-vars */
import gsap from 'gsap';

gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});

export class home_news {
  constructor(el) {
    this.el = el;
    this.active_index = 0;
    this.list = this.el.querySelector('#HomeNews ol');
    this.cards = this.list.querySelectorAll('li');

    this.card_width = this.cards[0].offsetWidth;
    this.back_distance = (window.innerWidth - this.card_width) / 2;
    this.start_pos =
      (this.cards.length / 2) * this.card_width * -1 + this.back_distance;

    this.timer = null;
    this.change_second = 5;

    this.timeline = null;
    this.setup();
  }
  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timeline = gsap.timeline();

    let time_line = this.cards[this.active_index].querySelector('.time_line');
    let time_line_2 =
      this.cards[this.active_index + this.cards.length / 2].querySelector(
        '.time_line'
      );

    this.timeline.set(
      this.list,
      {
        x: this.start_pos + 'px',
      },
      0
    );
    this.timeline.fromTo(
      time_line,
      {
        x: '-105%',
      },
      {
        x: '0',
        duration: this.change_second,
      },
      0
    );
    this.timeline.fromTo(
      time_line_2,
      {
        x: '-105%',
      },
      {
        x: '0',
        duration: this.change_second,
      },
      0
    );

    this.timer = setTimeout(() => {
      this.NextList();
    }, 5000);
  }

  NextList() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timer != null ? clearTimeout(this.timer) : '';
    this.ClearTimeLine();
    this.timeline = gsap.timeline();

    if (this.active_index == this.cards.length / 2 - 2) {
      this.timeline.set(this.list, {
        x: this.start_pos + this.card_width * 2 + 'px',
      });
      this.timeline.to(this.list, {
        x: this.start_pos + this.card_width * 1 + 'px',
        duration: 1,
      });
      this.active_index += 1;
    } else if (this.active_index == this.cards.length / 2 - 1) {
      this.timeline.to(this.list, {
        x: this.start_pos + 'px',
        duration: 1,
      });
      this.active_index = 0;
    } else {
      this.timeline.to(this.list, {
        x: this.start_pos - this.card_width * (this.active_index + 1) + 'px',
        duration: 1,
      });
      this.active_index += 1;
    }

    this.SetTimeLine();

    this.timer = setTimeout(() => {
      this.NextList();
    }, 5000);
  }

  PrevList() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timer != null ? clearTimeout(this.timer) : '';
    this.ClearTimeLine();
    this.timeline = gsap.timeline();

    if (this.active_index == 1) {
      this.timeline.set(this.list, {
        x: this.start_pos - this.card_width + 'px',
      });
      this.timeline.to(this.list, {
        x: this.start_pos + 'px',
        duration: 1,
      });
      this.active_index -= 1;
    } else {
      this.active_index == 0
        ? (this.active_index = this.cards.length / 2 - 1)
        : (this.active_index = this.active_index - 1);
      this.timeline.to(this.list, {
        x:
          this.start_pos +
          this.card_width * (this.cards.length / 2 - this.active_index) +
          'px',
        duration: 1,
      });
    }

    this.SetTimeLine();

    this.timer = setTimeout(() => {
      this.NextList();
    }, 5000);
  }

  SetTimeLine() {
    let time_line = this.cards[this.active_index].querySelector('.time_line');
    let time_line_2 =
      this.cards[this.active_index + this.cards.length / 2].querySelector(
        '.time_line'
      );

    this.timeline.fromTo(
      time_line,
      {
        x: '-105%',
      },
      {
        x: '0',
        duration: this.change_second,
      },
      0
    );

    this.timeline.fromTo(
      time_line_2,
      {
        x: '-105%',
      },
      {
        x: '0',
        duration: this.change_second,
      },
      0
    );
  }

  ClearTimeLine() {
    let time_line = this.el.querySelectorAll('.time_line');
    gsap.set(time_line, {
      x: '-105%',
    });
  }

  destroy() {
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timeline != null ? this.timeline.kill() : '';
  }
}

// export default {
//     data() {
//         return {
//             active_index: 0,
//             timer: null,
//             change_second: 5,
//             time_line_gsap: null
//         }
//     },
//     methods: {
//         SetList() {
//             let list = document.querySelector("#HomeNews ol")
//             let cards = list.querySelectorAll("li");
//             let card_width = cards[0].offsetWidth
//             let back_distance = (window.innerWidth - card_width) / 2
//             let start_pos = (cards.length / 2) * card_width * -1 + back_distance

//             gsap.set(list, {
//                 x: start_pos + "px"
//             })
//             this.time_line_gsap = gsap.timeline()
//             this.SetTimeLine()
//             this.timer = setTimeout(() => {

//                 if (this.$route.name == "home") {
//                     this.NextList()
//                 }
//             }, 5000);
//         },
//         NextList() {
//             clearTimeout(this.timer)
//             this.ClearTimeLine()
//             let list = document.querySelector("#HomeNews ol")
//             let cards = list.querySelectorAll("li");
//             let card_width = cards[0].offsetWidth
//             let back_distance = (window.innerWidth - card_width) / 2
//             let start_pos = (cards.length / 2) * card_width * -1 + back_distance
//             let list_time_line = gsap.timeline()

//             if (this.active_index == cards.length / 2 - 2) {
//                 list_time_line.set(list, {
//                     x: (start_pos + (card_width * 2)) + "px"
//                 })
//                 list_time_line.to(list, {
//                     x: (start_pos + (card_width * 1)) + "px",
//                     duration: 1
//                 })
//                 this.active_index += 1
//             } else if (this.active_index == cards.length / 2 - 1) {
//                 list_time_line.to(list, {
//                     x: start_pos + "px",
//                     duration: 1
//                 })
//                 this.active_index = 0
//             } else {
//                 list_time_line.to(list, {
//                     x: (start_pos - (card_width * (this.active_index + 1))) + "px",
//                     duration: 1
//                 })
//                 this.active_index += 1
//             }
//             this.SetTimeLine()
//             this.timer = setTimeout(() => {

//                 if (this.$route.name == "home") {
//                     this.NextList()
//                 }
//             }, 5000);
//         },
//         PrevList() {
//             clearTimeout(this.timer)
//             this.ClearTimeLine()
//             let list = document.querySelector("#HomeNews ol")
//             let cards = list.querySelectorAll("li");
//             let card_width = cards[0].offsetWidth
//             let back_distance = (window.innerWidth - card_width) / 2
//             let start_pos = (cards.length / 2) * card_width * -1 + back_distance
//             let list_time_line = gsap.timeline()

//             if (this.active_index == 1) {
//                 list_time_line.set(list, {
//                     x: (start_pos - card_width) + "px"
//                 })
//                 list_time_line.to(list, {
//                     x: (start_pos) + "px",
//                     duration: 1
//                 })
//                 this.active_index -= 1
//             } else {
//                 this.active_index == 0 ? this.active_index = cards.length / 2 - 1 : this.active_index = this.active_index - 1
//                 list_time_line.to(list, {
//                     x: (start_pos + (card_width * ((cards.length / 2) - this.active_index))) + "px",
//                     duration: 1
//                 })
//             }
//             this.SetTimeLine()
//             this.timer = setTimeout(() => {
//                 if (this.$route.name == "home") {
//                     this.NextList()
//                 }
//             }, 5000);
//         },
//         SetTimeLine() {
//             let list = document.querySelector("#HomeNews ol")
//             let cards = list.querySelectorAll("li");
//             let time_line = cards[this.active_index].querySelector(".time_line")
//             let time_line_2 = cards[this.active_index + (cards.length / 2)].querySelector(".time_line")

//             this.time_line_gsap.kill()
//             this.time_line_gsap = gsap.timeline()

//             this.time_line_gsap.fromTo(time_line, {
//                 x: "-105%"
//             }, {
//                 x: "0",
//                 duration: this.change_second,
//                 onComplete: () => {
//                     if (this.$route.name == "home") {
//                         gsap.set(time_line, {
//                             x: "-105%"
//                         })
//                     }
//                 }
//             }, 0)

//             this.time_line_gsap.fromTo(time_line_2, {
//                 x: "-105%"
//             }, {
//                 x: "0",
//                 duration: this.change_second,
//                 onComplete: () => {
//                     if (this.$route.name == "home") {
//                         gsap.set(time_line_2, {
//                             x: "-105%"
//                         })
//                     }
//                 }
//             }, 0)
//         },
//         ClearTimeLine() {
//             if (this.$route.name == "home") {
//                 let list = document.querySelector("#HomeNews ol")
//                 let cards = list.querySelectorAll("li");
//                 let time_line = cards[this.active_index].querySelector(".time_line")
//                 let time_line_2 = cards[this.active_index + (cards.length / 2)].querySelector(".time_line")
//                 this.time_line_gsap.kill()
//                 this.time_line_gsap = gsap.timeline()

//                 this.time_line_gsap.to(time_line, {
//                     x: "-105%",
//                     duration: 0
//                 }, 0)
//                 this.time_line_gsap.to(time_line_2, {
//                     x: "-105%",
//                     duration: 0
//                 }, 0)
//             }
//         }
//     },
//     computed: {
//         page_image_loaded() {
//             return this.$store.state.page_image_loaded
//         }
//     },
//     watch: {
//         page_image_loaded() {
//             if (this.page_image_loaded == true && this.news_data.length > 0) {
//                 this.$nextTick(() => {
//                     clearTimeout(this.timer)
//                     this.SetList()
//                 })
//             }
//         },
//         news_data() {
//             if (this.page_image_loaded == true && this.news_data.length > 0) {
//                 this.$nextTick(() => {
//                     clearTimeout(this.timer)
//                     this.SetList()
//                 })
//             }
//         }
//     },
//     beforeDestory() {
//         clearTimeout(this.timer)
//         clearTimeout(this.time_line_gsap)
//         this.active_index = 0
//     }
// }
