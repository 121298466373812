<template>
  <div
    v-if="dialog"
    id="OrderSearchDialog"
    class="
      fixed
      top-0
      left-0
      right-0
      bottom-0
      z-40
      flex
      items-center
      justify-center
    "
  >
    <div
      class="
        w-full
        md:h-auto
        h-full
        md:max-w-xl
        max-h-screen
        overflow-y-auto
        relative
        z-[41]
        bg-zinc-800
        shadow-2xl
        text-white
        md:py-12
        py-8
        px-8
        flex flex-col
      "
    >
      <div class="w-full mb-10">
        <h2
          class="
            text-white
            lg:text-5xl
            sm:text-2xl
            text-2xl
            font-black
            overflow-hidden
          "
        >
          <span class="block">ORDER SEARCH</span>
        </h2>
        <h2
          class="text-yellow-400 xl:text-2xl text-xl font-bold overflow-hidden"
        >
          <span class="block">訂單查詢</span>
        </h2>
      </div>
      <div class="w-full flex-1 -mx-2">
        <div class="flex flex-wrap">
          <div class="input_group md:w-1/2 w-full mb-4 md:px-2">
            <p class="text-sm mb-2 text-zinc-400">訂購人姓名</p>
            <input
              type="text"
              name="name"
              v-model="order_data.name"
              class="
                w-full
                px-2
                py-2
                focus:outline-none
                text-white
                bg-zinc-900
                block
              "
            />
          </div>
          <div class="input_group md:w-1/2 w-full mb-4 md:px-2">
            <p class="text-sm mb-2 text-zinc-400">訂購電話</p>
            <input
              type="tel"
              name="phone"
              v-model="order_data.phone"
              class="
                w-full
                px-2
                py-2
                focus:outline-none
                text-white
                bg-zinc-900
                block
              "
            />
          </div>
          <div class="input_group w-full mb-4 md:px-2">
            <p class="text-sm mb-2 text-zinc-400">訂購信箱</p>
            <input
              type="email"
              name="email"
              v-model="order_data.email"
              class="
                w-full
                px-2
                py-2
                focus:outline-none
                text-white
                bg-zinc-900
                block
              "
            />
          </div>
          <div class="input_group w-full mb-4 md:px-2">
            <p class="text-sm mb-2 text-zinc-400">訂單編號</p>
            <input
              type="text"
              name="trade_id"
              v-model="order_data.trade_id"
              class="
                w-full
                px-2
                py-2
                focus:outline-none
                text-white
                bg-zinc-900
                block
              "
            />
          </div>
        </div>
      </div>

      <div class="w-full md:px-4 flex justify-end">
        <button
          @click="Cancel"
          class="
            text-zinc-300 text-base
            border border-zinc-300
            px-8
            py-2
            mr-2
            font-bold
            inline-block
            transition-colors
            duration-500
            hover:bg-zinc-400 hover:text-black
          "
        >
          CANCEL
        </button>
        <button
          class="
            text-yellow-300 text-base
            border border-yellow-300
            px-8
            py-2
            font-bold
            inline-block
            transition-colors
            duration-500
            hover:bg-yellow-400 hover:text-black
          "
          @click="Search"
        >
          SUBMIT
        </button>
      </div>
    </div>
    <div
      class="absolute top-0 left-0 right-0 bottom-0 z-40 bg-black opacity-70"
    ></div>
  </div>
</template>

<script>
import { SearchOrder } from "@/api/shop_cart";
export default {
  name: "OrderSearchDialog",
  data() {
    return {
      order_data: {
        name: "",
        phone: "",
        email: "",
        trade_id: "",
      },
    };
  },
  methods: {
    Cancel() {
      this.$store.commit("SetOrderSearchDialog", false);
      this.order_data = {
        name: "",
        phone: "",
        email: "",
        order_no: "",
      };
    },
    Search() {
      SearchOrder(this.order_data).then((res) => {
        console.log(res);
        if (res.msg == "查無訂單") {
          this.$store.commit("SetMainDialog", {
            msg: "查無訂單",
            action: true,
          });
        } else {
          this.order_data = {
            name: "",
            phone: "",
            email: "",
            trade_id: "",
          };
          this.$store.commit("SetOrderSearchDialog", false);
          this.$store.commit("SaveOrderResult", res.data);
          this.$router.push("/order_search");
        }
      });
    },
  },
  computed: {
    dialog() {
      return this.$store.state.order_search_dialog;
    },
  },
};
</script>
    