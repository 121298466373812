<template>
  <div class="home">
    <Carousel ref="Carousel" class="fixed top-0 left-0 z-10" />
    <div class="main_content relative z-[11]">
      <MainBg />
      <!-- <Bg /> -->
      <About />
      <Marquee ref="Marquee01" class="my-20 md:mt-20" />
      <MainProduct01 ref="MainProduct01" />
      <MainProduct02 ref="MainProduct02" />
      <Marquee ref="Marquee02" class="mt-10 md:mt-20" />
      <ProductCategory />
      <News ref="News" />
      <Social />
      <MainContact class="mt-20" />
    </div>
  </div>
</template>

<script>
import MainBg from '@/components/MainBg.vue';
import Carousel from '@/components/Home/Carousel.vue';
import About from '@/components/Home/About.vue';
import MainProduct01 from '@/components/Home/MainProduct01.vue';
import MainProduct02 from '@/components/Home/MainProduct02.vue';
import Marquee from '@/components/Home/Marquee.vue';
import ProductCategory from '@/components/Home/ProductCategory.vue';
import News from '@/components/Home/News.vue';
import Social from '@/components/Home/Social.vue';
import MainContact from '@/components/MainContact.vue';
import { GetMetaData } from '@/common/meta';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  data() {
    return {
      carousel_height: 0,
      meta_data: null,
    };
  },
  components: {
    MainBg,
    Carousel,
    About,
    MainProduct01,
    MainProduct02,
    Marquee,
    ProductCategory,
    News,
    Social,
    MainContact,
  },
  methods: {
    SetMainContentMargin() {
      document.querySelector('.home .main_content').style.marginTop =
        document.querySelector('.home_carousel').offsetHeight + 'px';
    },
    PageInit() {
      this.meta_data = GetMetaData('官方網站', '', '');
      this.$nextTick(() => {
        this.$emit('load-image');
      });
    },
    SetGsap() {
      this.SetMainContentMargin();
      this.$refs.Carousel.SetGsap();
      this.$refs.Marquee01.SetMarqueeLeft();
      this.$refs.Marquee02.SetMarqueeRight();
      this.$refs.MainProduct01.SetGsap();
      this.$refs.MainProduct02.SetGsap();
      this.$refs.News.SetGsap();
      window.addEventListener('resize', () => {
        if (this.$route.name == 'home') {
          this.SetMainContentMargin();
        }
      });
      this.$nextTick(() => {
        window.prerenderReady = true;
      });
    },
  },
  watch: {
    page_image_loaded() {
      this.page_image_loaded ? this.SetGsap() : '';
    },
    data_load_finish() {
      this.data_load_finish ? this.PageInit() : '';
    },
  },
  computed: {
    ...mapState(['page_image_loaded']),
    ...mapGetters(['data_load_finish']),
  },
  created() {
    this.data_load_finish ? this.PageInit() : '';
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
