<template>
  <section id="MainContact" v-if="contact_data != null">
    <div class="relative z-10 w-full py-20 bg-center bg-cover">
      <div class="relative z-10 w-full max-w-screen-xl px-20 mx-auto xl:px-0">
        <h3
          class="mb-3 text-2xl font-bold leading-snug tracking-wider text-white"
        >
          {{ contact_data.title }}
        </h3>
        <p class="mb-8 text-sm text-gray-400" v-html="contact_data.content"></p>

        <a
          class="px-6 py-2 text-sm text-yellow-400 transition-colors duration-500 border border-yellow-400 hover:bg-yellow-400 hover:text-black"
          :href="contact_data.link"
        >
          CONTACT
        </a>
      </div>
      <div class="absolute top-0 left-0 right-0 bottom-0 z-[5]">
        <div
          class="absolute z-[5] top-0 left-0 right-0 bottom-0 bg-black opacity-80"
        ></div>
        <img
          :src="contact_data.image"
          class="block object-cover object-center w-full h-full grayscale opacity-70"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainContact',
  computed: {
    contact_data() {
      return this.$store.getters.get_common_column('home_contact');
    },
  },
};
</script>
