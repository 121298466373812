import { get, post_form } from '@/common/request';

export function GetCarouselData() {
  return get('carousel/get');
}

export function GetCommonColumnData() {
  return get('common_column/get');
}

export function GetDealerData() {
  return get('dealer/get');
}

export function GetNewsData() {
  return get('news_list/get');
}

export function GetProductData() {
  //data->
  // category
  //
  // products
  // product_type
  // products
  // tags
  // vendors
  return get('product/get');
}

export function GetQuestionData() {
  return get('question/get');
}

export function GetVideoData() {
  return get('video/get');
}

export function SendContact(form_data) {
  let shop_cart_data = new FormData();
  shop_cart_data.append('form_data', JSON.stringify(form_data));
  return post_form('contact/send', shop_cart_data);
}
