<template>
  <span
    id="mouse_arrow"
    class="
      hidden
      fixed
      z-[100]
      w-[40px]
      h-[40px]
      transform
      rounded-full
      pointer-events-none
      top-0
      left-0
      items-center
      justify-center
    "
  >
    <i
      class="
        absolute
        top-1/2
        left-1/2
        transform
        -translate-x-1/2 -translate-y-1/2
        w-[20px]
        h-[20px]
        rounded-full
        bg-yellow-400
        opacity-20
      "
    ></i>
    <i
      class="
        animate-ping
        w-[25px]
        h-[25px]
        rounded-full
        bg-yellow-400
        opacity-20
      "
    ></i>
  </span>
</template>

<script>
export default {
  name: "MouseArrow",

  methods: {
    InitCursor() {
      if (!("ontouchstart" in document.documentElement)) {
        window.addEventListener("mousemove", ($event) => {
          let cursor = document.getElementById("mouse_arrow");
          let w = cursor.offsetWidth;
          let h = cursor.offsetHeight;
          cursor.style.display = "flex";
          cursor.style.transform =
            "translate3D(" +
            ($event.clientX - w / 2) +
            "px," +
            ($event.clientY - h / 2) +
            "px,0px)";
        });
      }
    },
  },
};
</script>