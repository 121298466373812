import {
    post_form
} from "@/common/request"


export function CheckShopCart(shop_cart) {
    let shop_cart_data = new FormData();
    shop_cart_data.append("shop_cart", JSON.stringify(shop_cart))
    return post_form("shop_cart/check", shop_cart_data);
}


export function CreateOrder(shop_cart, form_data) {
    let shop_cart_data = new FormData();
    shop_cart_data.append("shop_cart", JSON.stringify(shop_cart))
    shop_cart_data.append("form_data", JSON.stringify(form_data))
    return post_form("shop_cart/create", shop_cart_data);
}

export function SearchOrder(form_data) {
    let shop_cart_data = new FormData();
    shop_cart_data.append("form_data", JSON.stringify(form_data))
    return post_form("shop_cart/search", shop_cart_data);
}